import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import Layout from '../components/Layout';

const PrivacyPolicyPage = (): JSX.Element => {
    return (
        <>
            <Helmet title="Privacy Policy" />
            <Layout>
                <div className="page">
                    <h1 className="page__title">Privacy Policy</h1>
                    <div className="page__cnt">
                        <p>
                            <b>Who are we?</b>
                            <br />
                            CC Media Network Ltd (“CCMN”, “we”, “us” or “our”) is the controller of your personal data for the purposes of data protection law. We are registered in Gibraltar with company registration number 119396, and are registered at Unit 3.21, World Trade Center, 6 Bayside Road, GX11 1AA, Gibraltar.
                        </p>
                        <p>
                            <b>What information do we collect?</b>
                            <br />
                            When you contact us, we will process your name and contact information, including email address, and any other information concerning your enquiry.
                        </p>
                        <p>
                            <b>Device and cookie information</b>
                            <br />
                            When you visit our website, we automatically record information from your browser or mobile platform, including your IP address, internet service provider’s domain name, which pages you accessed on the site, and when.
                            <br />
                            Details of cookies used on our website can be found in our&nbsp;
                            <Link to="/cookie-policy/">cookie policy</Link>.
                        </p>
                        <p>
                            <b> What do we use your data for?</b>
                        </p>
                        <p>
                            <b>Improving the website</b>
                            <br />
                            We use device and cookie data in aggregate form so that we can understand how users interact with our website and to help us generally enhance the user experience.
                        </p>
                        <p>
                            <b>Responding to contact enquiries</b>
                            <br />
                            We use the information you send to us to help us respond to your contact request and to manage our relationship with you.
                        </p>
                        <p>
                            <b>What legal basis do we have for processing?</b>
                            <br />
                            We process some of your data because it is in our legitimate interests to do so, and when you contact us because you have asked us to do so. We will consider the impact using your information may have on you, and limit our use to only what is strictly necessary. In the case of improving our web services, it is in our legitimate interest as an organisation to ensure that we provide you with the best possible service. We believe that it is within the reasonable expectations of the visitors to our website that we would use their information in this way.
                        </p>
                        <p>
                            <b>Data transfers and third-party use of information</b>
                            <br />
                            Other than as described in this section, we will never disclose, sell or rent your personal information to any third party, and we will not transfer your data outside of our company.
                            <br />
                            If a third party acquires all (or substantially all) CCMN’s business and/or assets, we may disclose your personal information to that third party in connection with the acquisition. CCMN may also disclose your personal information where required to do so by applicable law, to a governmental body or law enforcement agency.
                        </p>
                        <p>
                            <b>Security</b>
                            <br />
                            We have physical, electronic, and managerial procedures to safeguard and secure the information we collect. Please remember: You provide personal data at your own risk, and no data transmission is guaranteed to be 100% secure.
                            <br />
                            If you believe your privacy has been breached, please contact us immediately at <a href="mailto:dpo@ccmedianetwork.com">dpo@ccmedianetwork.com</a>
                        </p>
                        <p>
                            <b>How long do we store your data?</b>
                            <br />
                            The amount of time we hold your data varies according to the purpose we have for using it. However, we will not hold data for any longer than is necessary for the purposes we collected it.
                            <br />
                            Details on specific retention periods for our data can be obtained by contacting us.
                        </p>
                        <p>
                            <b>Your rights</b>
                            <br />
                            You have the right to access information we hold about you and you have the right to make us correct any inaccurate personal data about you. You can object to us using your data for profiling you or making automated decisions about you. You have the right to be ‘forgotten’ by us.
                        </p>
                        <p>
                            You can exercise your rights by sending us an email to <a href="mailto:dpo@ccmedianetwork.com">dpo@ccmedianetwork.com</a>
                        </p>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default PrivacyPolicyPage;
